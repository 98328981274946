@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  
}
@layer utilities {
  .caret-hide {
    caret-color: transparent;
  }
}
